<template>
  <div class="wrapper">
    <AppLayoutNav />
    <AppLayoutMenu />
    <div class="content-wrapper">

    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>{{ title }}</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="#">Home</a></li>
              <li class="breadcrumb-item active">Table</li>
            </ol>
          </div>
        </div>
      </div><!-- /.container-fluid -->
    </section>

    <slot />
    </div>
    <footer class="main-footer">
      <div class="float-right d-none d-sm-block">
        <b>Version</b> 1.1
      </div>
      <strong>Copyright &copy; 2021 <a href="http://boscod.com">boscod.com</a>.</strong> All rights
      reserved.
    </footer>
  </div>
</template>

<script>
import Vue from 'vue'
import AppLayoutNav from '@/layouts/AppLayoutNav'
import AppLayoutMenu from '@/layouts/AppLayoutMenu'

export default {
  name: "AppLayoutAdmin",
  data() {
    return {
      title: null,
    };
  },mounted() {
      if(Vue.config.enableChat)
        this.chat()
        console.log('layout admin')
    },
  created: function () {
    // `this` points to the vm instance
    console.log(this.$route)
    this.title = this.$route.name;
  },
  methods: {
    chat: function(){
      var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
      (function(){
        var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
        s1.async=true;
        s1.src='https://embed.tawk.to/61283dd7d6e7610a49b2375d/1fe2j35je';
        s1.charset='UTF-8';
        s1.setAttribute('crossorigin','*');
        s0.parentNode.insertBefore(s1,s0);
      })();
    }
  },
  components: {
    AppLayoutNav, AppLayoutMenu
  }
}
</script>

<style scoped>
</style>
